import React from 'react'
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'

// Images
import Houses from 'img/houses.inline.svg'

const Wrapper = styled.section`
  height: 80vh;
  width: 100%;

  & a {
    text-decoration: underline;
  }
`

const Inner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
`

const HouseWrapper = styled.div`
  & svg {
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <Wrapper className="d-flex justify-content-center align-items-center">
      <Inner className="w-100 text-center py-5 position-relative">
        <h1>404</h1>
        <p>Oeps, we konden deze pagina niet vinden.</p>
        <a href="/">Terug naar home</a>
        <HouseWrapper
          className="position-absolute"
          style={{ top: '-74px', left: 'calc(50% - 75px)' }}
        >
          <Houses />
        </HouseWrapper>
      </Inner>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
